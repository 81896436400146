import get from 'lodash.get'
import isDev from './is-dev'
import isClient from './is-client'

const api = {}
const cache = {}

api.set = (key, value) => cache[key] = value
api.get = key => key ? get(cache, key, null) : cache

if (isDev && isClient) {
  window.APP = window.APP || {}
  window.APP.cache = api
}

export default api
