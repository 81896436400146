import React from 'react'
import ReactGA from 'react-ga'
import '../styles/global.css'
import Helmet from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline'
import isClient from '../helpers/is-client'
import isDev from '../helpers/is-dev'
import isTouchscreen from '../helpers/is-touch-screen'
import Awwwards from '../components/shared/Awwwards'
import Fonts from '../components/shared/Fonts'
import fonts from '../data/global-fonts'
import { ThemeProvider } from '@material-ui/core/styles'
import { PRELAUNCH } from '../../functions/env'
import theme from '../styles/theme'
import cache from '../helpers/cache'
cache.set('theme', theme)

if (isClient) {
  window.ScrollMagic = require('scrollmagic')

  if (isDev) {
    const script = window.document.createElement('script')
    script.type = 'text/javascript'
    script.src = '/scrollmagic/plugins/debug.addIndicators.min.js'
    window.document.head.appendChild(script)
  }

  const gsap = require('gsap')
  window.TweenMax = gsap.TweenMax
  window.TweenLite = gsap.TweenLite
  window.TimelineMax = gsap.TimelineMax
  window.TimelineLite = gsap.TimelineLite
  window.Linear = gsap.Linear
  window.Power0 = gsap.Power0
  window.Power1 = gsap.Power1
  window.Power2 = gsap.Power2
  window.Power3 = gsap.Power3
  window.Power4 = gsap.Power4
  gsap.default.registerPlugin(
    require('gsap/CSSPlugin'),
    require('gsap/ScrollToPlugin')
  )

  const { ScrollMagicPluginGsap } = require('scrollmagic-plugin-gsap')
  ScrollMagicPluginGsap(
    window.ScrollMagic,
    window.TweenMax,
    window.TimelineMax
  )
}

const title = 'All I Font for Christmas'
const keywords = 'christmas,fonts,typography,advent,calendar'
const description = 'Typographic Holiday Calendar'
const fbImage = 'https://www.allifontforchristmas.com/social/1200x630.png'
const twImage = 'https://www.allifontforchristmas.com/social/800x800.png'
const twSite = '@_zak_fisher'
const url = 'https://allifontforchristmas.com'

const AdSense = () => (
    <Helmet>
        <script
            data-ad-client="ca-pub-5604392701701134"
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
    </Helmet>
);

export default ({ children }) => {
  const [element, setElement] = React.useState(PRELAUNCH ? null : children)

  // Pre-launch Check
  React.useEffect(() => {
    if (!PRELAUNCH || element) return
    cache.set('touch', isTouchscreen)
    const isProd = window.location.href.includes('allifontforchristmas.com')
    setElement(isProd ? <p>Good things come to those who wait...</p> : children)
  }, [children, element])

  // Google Analytics
  React.useEffect(() => {
    if (isDev) return
    if (!window.location.href.includes('allifontforchristmas.com')) return
    const gaId = 'UA-153278767-1'
    ReactGA.initialize(gaId)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={fbImage} />
        <meta property="og:url" content={url} />
        <meta name="twitter:site" content={twSite} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={twImage} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Fonts fonts={fonts} />
      {element}
      <Awwwards />
      <AdSense />
    </ThemeProvider>
  )
}
