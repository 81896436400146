import { createMuiTheme } from '@material-ui/core/styles'
import isTouchScreen from '../helpers/is-touch-screen'

export default createMuiTheme({
  palette: {
    black: '#242424',
    white: 'white',
    red: '#E02020',
    softRed: 'rgba(224,32,32,0.3)',
    pink: '#FFA9ED',
    purple: '#6059D1',
    green: '#2AC276'
  },
  mixins: {
    ellipsis: (width = '100%') => ({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width
    }),
    hover: style => isTouchScreen ? {} : style,
  },
  vars: {
    logoHeight: 70,
    logoWidth: 168
  }
})
