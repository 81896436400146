import React from "react"
import Helmet from 'react-helmet'
import { default as minifyCss } from 'minify-css-string'
import { getFontSrc, getStyles } from '../../../helpers/fonts'

const Fonts = ({ fonts }) => {
  if (!fonts || !fonts.length) return null

  const stylesheets = []
  const css = []
  fonts.forEach((font, i) => {
    if (font.provider === 'google') {
      stylesheets.push(<link key={i} rel="stylesheet" href={getFontSrc(font)} />)
    }
    const globalRule = font.globalDefault ? '*, ' : ''
    const fontRule = `${globalRule}[data-font="${font.name}"]`
    css.push(font.fontFace || '')
    css.push(`${fontRule} { ${font.css} }`)
    getStyles(font.styles).forEach(style => {
      css.push(`${fontRule}[data-style="${style.name}"] { ${style.css} }`)
    })
  })
  const style = <style>{minifyCss(css.join(''))}</style>

  return fonts.map((font, i) => (
    <Helmet key={i}>
      {stylesheets}
      {style}
    </Helmet>
  ))
}

Fonts.defaultProps = {
  fonts: []
}

export default Fonts
