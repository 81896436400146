/* Global Fonts */

const Lato =   {
  provider: 'google',
  name: 'Lato',
  family: 'Lato:400,700',
  globalDefault: true,
  css: `font-family: 'Lato', sans-serif; font-weight: 400;`,
  styles: '400,700'
}

const BreeSerif = {
  provider: 'google',
  name: 'Bree Serif',
  family: 'Bree+Serif',
  css: `
    font-family: 'Bree Serif', serif;
    font-weight: 400;
  `,
  styles: '400'
}

export default [ Lato, BreeSerif ]
