const moment = require('moment')

const NOW = moment.utc()
const START_DATE = moment.utc(new Date(2019, 11, 1)) // Dec 1, 2019
const END_DATE = moment.utc(new Date(2020, 0, 1))    // Jan 1, 2020

exports.START_DATE = START_DATE
exports.END_DATE = END_DATE
exports.PRELAUNCH = NOW < START_DATE

exports.EMAIL_RECIPIENT = 'zak.fisher@gmail.com,lmweber333@gmail.com'
exports.MAILGUN_API_KEY = 'key-a879cdfca88acd4f696f28415be51ffa'
exports.MAILGUN_DOMAIN = 'sandbox5fb304fd684146a2ad8d07a16e7648b3.mailgun.org'

exports.SERVE_ALL_FONTS = null
exports.SKIP_INTRO = null
exports.DEBUG_CALENDAR = null
exports.DEBUG_SCROLLMAGIC = null
