export const fontProps = (name, style) => ({
  'data-font': name,
  'data-style': style || 'Regular'
})

export const getFontSrc = font => {
  if (font.provider === 'google') {
    const family = font.name.replace(/ /g, '+') + ':' + font.styles
    return `https://fonts.googleapis.com/css?family=${family}&display=swap`
  }
  return null
}

export const downloadFont = font => {
  window.open(`/fonts/download/${font.name.replace(/ /g, '_')}.zip`)
}

const styleName = {
  100: 'Thin',
  200: 'Extra Light',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'Semi-Bold',
  700: 'Bold',
  800: 'Extra Bold',
  900: 'Black',
  1000: 'Extra Black',
}

export const getFontStyle = weight => ({
  name: styleName[weight.replace('i', '')] + (weight.includes('i') ? ' Italic' : ''),
  css: `font-weight: ${weight.replace('i', '')};` + (weight.includes('i') ? ' font-style: italic; font-variation-settings: "slnt" -15;' : '')
})

export const getStyles = weights => weights.split(',').map(getFontStyle)